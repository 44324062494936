<script>
    import { payment } from '$lib/stores'
    import { captureMessage } from '$lib/utilities/errorTrackingTools.js'
    import SiruAxios from '$lib/SiruAxios'
    import { _ } from 'svelte-i18n'

    export let variant = 'btn-primary'

    let loading = false

    const onClickHandler = () => {
        if ($payment.uuid) {
            loading = true
            SiruAxios.get(`pay/rest/${$payment.uuid}/finalize`, {
                useGlobalErrorHandler: false,
            })
                .then(({ data }) => (window.location.href = data.redirect))
                .catch(() => {
                    // @TODO check how these work with our error handling
                    captureMessage('Fallback to window.history.back() in ReturnToMerchantButton')
                    window.history.back()
                    loading = false
                })
        } else {
            window.history.back()
        }
    }
</script>

<div class="d-grid gap-2 text-center">
    <button on:click={onClickHandler} class={`btn btn-lg ${variant} mb-3 ${loading ? 'disabled' : ''}`}>
        {$_('payment.button.return_to_merchant')}
    </button>
</div>
